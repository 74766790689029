function openModal(vimeoID) {
  const modal = document.querySelector(".film-modal");
  const videoContainer = modal.querySelector(".video-container");
  videoContainer.innerHTML = "";
  videoContainer.innerHTML = `<iframe src="https://player.vimeo.com/video/${vimeoID}?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:2.5%;width:95%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
  window.setTimeout(function () {
    modal.classList.add("active");
  }, 30);
}

function closeModal() {
  const modal = document.querySelector(".film-modal");
  const videoContainer = modal.querySelector(".video-container");
  history.replaceState({}, document.title, ".");
  modal.classList.remove("active");
  window.setTimeout(function () {
    videoContainer.innerHTML = "";
  }, 350);
}

function getItemBySlug(slug) {
  const items = document.querySelectorAll(".film-card");
  let result = false;
  items.forEach((item) => {
    if (item.getAttribute("data-slug") === slug) {
      result = item;
    }
  });
  return result;
}

function initFilms() {
  const filmCards = document.querySelectorAll(".film-card");
  if (filmCards.length > 0) {
    let closeButton = document.querySelector(".close-modal");
    closeButton.addEventListener("click", (e) => {
      closeModal();
    });
    window.addEventListener("keyup", e => {
      if (e.key === "Escape") {
        closeModal()
      }
    })
    filmCards.forEach((card) => {
      let image = card.querySelector(".film-card--image");
      let title = card.querySelector(".film-card--title");
      let vimeoID = card.getAttribute("data-ff-vimeo-id");
      if (vimeoID) {
        image.addEventListener("click", function () {
          window.location.hash = card.getAttribute("data-slug")
          openModal(vimeoID);
        });
        title.addEventListener("click", function () {
          window.location.hash = card.getAttribute("data-slug")
          openModal(vimeoID);
        });
      }
    });
  }

  if (window.location.hash) {
    const slug = window.location.hash.replace("#", "");
    if (getItemBySlug(slug)) {
      console.log("slug was found, fetching...");
      let item = getItemBySlug(slug)
      openModal(item.getAttribute("data-ff-vimeo-id"))
    }
  }
}

export { initFilms, openModal, closeModal };
