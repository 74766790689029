import axios from "axios";
import { initCard } from "./initCard";
import { initFilms } from "./initFilms";
import { initNews } from "./initNews";

const arrow = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="97px"
	 height="95.3px" viewBox="0 0 97 95.3" style="overflow:visible;enable-background:new 0 0 97 95.3;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<defs>
</defs>
<g>
	<line class="st0" x1="2.3" y1="48.3" x2="48.5" y2="2.3"/>
	<line class="st0" x1="94.7" y1="48.3" x2="48.5" y2="2.3"/>
	<line class="st0" x1="48.5" y1="4.1" x2="48.5" y2="92.9"/>
</g>
</svg>
`;

function renderPosts(html) {
  const container = document.querySelector(".post-list--wrapper");
  container.classList.remove("loading");
  container.innerHTML = html;
  initCard();
  initNews();
  initFilms();
}

const applyFilter = function (postType, taxonomy, value) {
  const params = new URLSearchParams();
  params.append("action", "ff_get_filtered_posts");
  params.append("post_type", postType);
  params.append("taxonomy", taxonomy);
  params.append("value", value);

  axios
    .post("/wp-admin/admin-ajax.php", params)
    .then(function (response) {
      renderPosts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

function setCurrentPage(n) {
  const pages = document.querySelectorAll(".filter-page");
  const next = document.querySelector(".next");
  const prev = document.querySelector(".prev");

  pages.forEach((p) => {
    p.classList.remove("active");
  });

  next.classList.remove("hidden");
  prev.classList.remove("hidden");

  if (n === pages.length - 1) {
    next.classList.add("hidden");
  }
  if (n === 0) {
    prev.classList.add("hidden");
  }

  pages[n].classList.add("active");
}

function paginateFilters(filters) {
  let currentPage = 0;
  let filtersPerPage = 3;
  if (window.matchMedia("(min-width: 1000px)").matches) {
    filtersPerPage = 6;
  }
  const filterContainer = document.querySelector(".filters");

  let pageCount = Math.ceil(filters.length / filtersPerPage);
  for (let i = 0; i < pageCount; i++) {
    let pageEl = document.createElement("div");
    pageEl.classList.add("filter-page");

    for (let j = 0; j < filtersPerPage; j++) {
      let filter = filters[i * filtersPerPage + j];
      if (filter) {
        pageEl.appendChild(filter);
      }
    }
    filterContainer.appendChild(pageEl);
  }
  const nextButton = document.createElement("button");
  const prevButton = document.createElement("button");
  nextButton.innerHTML = arrow;
  nextButton.classList.add("next");

  prevButton.innerHTML = arrow;
  prevButton.classList.add("prev");

  filterContainer.insertAdjacentElement("afterbegin", prevButton);
  filterContainer.insertAdjacentElement("beforeend", nextButton);

  nextButton.addEventListener("click", () => {
    currentPage += 1;
    setCurrentPage(currentPage);
  });
  prevButton.addEventListener("click", () => {
    currentPage -= 1;
    setCurrentPage(currentPage);
  });

  setCurrentPage(currentPage);
}

function initFilters() {
  const filters = document.querySelectorAll(".filter");
  const container = document.querySelector(".post-list--wrapper");
  filters.forEach((f, i) => {
    f.addEventListener("click", (e) => {
      filters.forEach((fi) => {
        fi.classList.remove("active");
      });
      f.classList.add("active");
      container.classList.add("loading");
      let postType = f.getAttribute("data-post-type");
      let taxonomy = f.getAttribute("data-taxonomy");
      let value = f.getAttribute("data-value");
      applyFilter(postType, taxonomy, value);
    });
  });

  if (filters.length > 0) {
    paginateFilters(filters);
  }
}

export { initFilters };
