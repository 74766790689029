const { initNews } = require("./initNews");
const { initCard } = require("./initCard");
const { initProject } = require("./initProject");
const { initFilms } = require("./initFilms");
const { initFilters } = require("./initFilters");
const { initHome } = require("./initHome");
const { initNav } = require("./initNav");

document.addEventListener("DOMContentLoaded", () => {
  initHome();
  initNews();
  initCard();
  initFilms();
  initFilters();
  initNav();
  initProject();
});
