function initCard() {
  const cardImages = document.querySelectorAll(".lazy-image");
  cardImages.forEach((container) => {
    let img = container.querySelector(".lazy-image--full");
    if (img.complete) {
      container.classList.add("loaded");
      container.classList.add("cached");
    }
    img.addEventListener("load", () => {
      container.classList.add("loaded");
    });
  });
}

export { initCard };
