function initNav() {
  const header = document.querySelector(".site-header");
  const toggleNav = document.querySelector(".toggle-nav");
  const closeNav = document.querySelector(".close-nav");
  toggleNav.addEventListener("click", (e) => {
    document.body.classList.toggle("nav-active");
  });
  if (closeNav) {
    closeNav.addEventListener("click", (e) => {
      document.body.classList.remove("nav-active");
    });
  }

  window.lastScrollY = 0;
  window.addEventListener("scroll", (e) => {
    if (window.scrollY > window.lastScrollY) {
      // We're scrolling down
      document.body.classList.remove("header-active");
    } else {
      // We're scrolling up
      document.body.classList.add("header-active");
    }
    // Force header when we're at the top
    if (window.lastScrollY < 100) {
      document.body.classList.add("header-active");
    }
    window.lastScrollY = window.scrollY;
  });
}

export { initNav };
