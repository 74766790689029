import { openModal, closeModal } from "./initFilms.js"

function handleIntersection(events) {
  events.forEach((e) => {
    if (e.intersectionRatio > 0) {
      e.target.classList.add("in-view");
    } else {
    }
  });
}
function closeLightbox() {
  const modal = document.querySelector(".lightbox");
  const imageContainer = modal.querySelector(".image-container");
  modal.classList.remove("active");
  window.setTimeout(function () {
    imageContainer.innerHTML = "";
  }, 150);
}

function openLightbox(img) {
  const modal = document.querySelector(".lightbox");
  const imageContainer = modal.querySelector(".image-container");
  const src = img.getAttribute("src");
  imageContainer.innerHTML = "";
  imageContainer.innerHTML = `<img src="${src}"/>`;

  window.setTimeout(function () {
    modal.classList.add("active");
  }, 30);
}

function initProject() {
  let observer = new IntersectionObserver(handleIntersection, {
    root: null,
    threshold: 0,
  });

  let closeButton = document.querySelector(".close-modal");
  closeButton.addEventListener("click", (e) => {
    closeLightbox();
  });
  window.addEventListener("keyup", (e) => {
    if (e.key === "Escape") {
      closeLightbox();
    }
  });

  const galleryImages = document.querySelectorAll(
    ".single-project--gallery img"
  );
  const galleryRows = document.querySelectorAll(
    ".single-project--gallery .gallery--row"
  );
  galleryImages.forEach((img) => {
    observer.observe(img);
    img.addEventListener("click", function () {
      openLightbox(img);
    });
  });

  function setEqualHeight(images, container) {
    console.log(container)
    let b0 = images[0].getBoundingClientRect();
    let b1 = images[1].getBoundingClientRect();
    let h0 = b0.height - 10;
    let h1 = b1.height - 10;
    container.style.height = `${Math.min(h0, h1)}px`;
  }

  if (window.matchMedia("(min-width: 850px)").matches) {
    galleryRows.forEach((row) => {
      let images = row.querySelectorAll(".gallery--image img");
      let loadedImages = 0;
      images.forEach((img) => {
        if (img.complete) {
          loadedImages++
          img.classList.add("loaded")
        }
        if (loadedImages === images.length && images.length > 1) {
          setEqualHeight(images, row);
        }
        img.addEventListener("load", () => {
          loadedImages++;
          img.classList.add("loaded")
          if (loadedImages === images.length && images.length > 1) {
            setEqualHeight(images, row);
          }
        });
      });
    });
  }

  const filmButton = document.querySelector(".related_film")
  if (filmButton) {
    filmButton.addEventListener("click", () => {
      openModal(filmButton.getAttribute("data-ff-vimeo-id"))
    })
  }
  let closeFilmButton = document.querySelector(".film-modal .close-modal");
  closeFilmButton.addEventListener("click", (e) => {
    closeModal();
  });
  window.addEventListener("keyup", e => {
    if (e.key === "Escape") {
      closeModal();
    }
  })
}
export { initProject };
