function initHome() {
  // We wait until both videos are loaded
  const videos = document.querySelectorAll(".home-tile video");
  const loading = document.querySelector(".home-loading");
  const videoContainer = document.querySelector(".home-content");
  let loadedVideos = 0;

  if (videos.length > 0) {
    videos.forEach((v) => {
      v.addEventListener("loadeddata", (e) => {
        console.log(v.readyState);
        if (v.readyState >= 3) {
          loadedVideos += 1;
        }
        if (loadedVideos === videos.length) {
          loading.classList.remove("active");
          videoContainer.classList.add("loaded");
        }
      });
    });

    // Fallback
    window.setTimeout(() => {
      loading.classList.remove("active");
      videoContainer.classList.add("loaded");
    }, 4000);
  }
}

export { initHome };
