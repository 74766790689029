function handleNewsScroll(e) {
  const newsItems = document.querySelectorAll(".news-list--item");

  // for-loop so we can break
  for (let i = 0; i < newsItems.length; i++) {
    let ni = newsItems[i];
    let box = ni.getBoundingClientRect();
    if (box.y < 0) {
      ni.classList.remove("visible");
    } else {
      ni.classList.add("visible");
      break;
    }
  }

  const visibleItems = document.querySelectorAll(".news-list--item.visible");
  newsItems.forEach((vi) => {
    vi.classList.remove("top");
  });
  visibleItems[0].classList.add("top");
}

function initNews() {
  const newsItems = document.querySelectorAll(".news-list--item");
  if (newsItems.length > 0) {
    newsItems[0].classList.add("top");
    window.addEventListener("scroll", handleNewsScroll);
  }
}

export { initNews };
